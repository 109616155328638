<template>
  <section class="home--search-container">
    <div class="home--search-bg">
      <img
        src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/static/homepage-2.jpeg"
      />
      <div class="bg--cover"></div>
    </div>
    <div class="container">
      <div class="search--container">
        <h2 class="title--text">
          {{ $t('home.title') }}
        </h2>
        <form
          class="search--form"
          :class="{ 'flex-wrap': $mq === 'xs' || $mq === 'sm' }"
          @submit.prevent="goToSearch"
        >
          <div class="search--project-wrapper" :class="{ 'mb-2': $mq === 'xs' || $mq === 'sm' }">
            <!--            <client-only>-->
            <!--              <vue-bootstrap-typeahead-->
            <!--                ref="searchTypehead"-->
            <!--                :data="suggestions"-->
            <!--                v-model="currentKeyword"-->
            <!--                inputClass="basic&#45;&#45;input"-->
            <!--                class="input&#45;&#45;wrapper"-->
            <!--                :serializer="s => s.name"-->
            <!--                :placeholder="$t('home.searchBarPlaceholder')"-->
            <!--                @hit="selectedSuggestion"-->
            <!--              >-->
            <!--                <template slot="suggestion" slot-scope="{ data, htmlText }">-->
            <!--                  <div class="d-flex align-center">-->
            <!--                    <i-->
            <!--                      class="ion-earth"-->
            <!--                      v-if="data.category === 'city' || data.category === 'province'"-->
            <!--                    ></i>-->
            <!--                    <i class="icon icon&#45;&#45;building icon&#45;&#45;sm" v-if="data.category === 'project'"></i>-->
            <!--                    <span class="ml-4" v-html="htmlText"></span>-->
            <!--                  </div>-->
            <!--                </template>-->
            <!--              </vue-bootstrap-typeahead>-->
            <!--            </client-only>-->
            <multiselect-region :is-not-search="true"></multiselect-region>
            <v-select
              v-model="selectedPropertyType"
              :items="propertyTypeFilters"
              :label="$t('general.filter.propertyType')"
              item-value="id"
              item-text="name"
              class="filter--dropdown left"
              solo
              hide-details
              return-object
            >
              <template v-slot:selection="{ item, index }">
                {{ item.name }}
              </template>
            </v-select>
            <button class="input--btn" type="submit">
              <!--              <img src="@/assets/img/icons/search.svg" alt="Cari" />-->
              {{ $t('general.search') }}
            </button>
          </div>
          <!--          <v-select-->
          <!--            v-model="minBedroom"-->
          <!--            :items="minBedrooms"-->
          <!--            :label="$t('general.filter.minBedroom')"-->
          <!--            item-value="id"-->
          <!--            item-text="name"-->
          <!--            class="filter&#45;&#45;dropdown left"-->
          <!--            :class="{ 'ml-3': $mq !== 'xs' || $mq === 'sm', 'mb-2': $mq === 'xs' }"-->
          <!--            solo-->
          <!--            hide-details-->
          <!--            return-object-->
          <!--          >-->
          <!--            <template v-slot:selection="{ item, index }">-->
          <!--              {{ item.name }}-->
          <!--            </template>-->
          <!--          </v-select>-->
          <!--          <v-menu ref="priceMenu" content-class="filter&#45;&#45;menu" :close-on-content-click="false">-->
          <!--            <template v-slot:activator="{ on, attrs }">-->
          <!--              <div-->
          <!--                class="filter&#45;&#45;menu-button"-->
          <!--                v-bind="attrs"-->
          <!--                v-on="on"-->
          <!--                :class="{ 'ml-3': $mq !== 'xs' || $mq === 'sm' }"-->
          <!--              >-->
          <!--                <div :class="{ 'filter&#45;&#45;menu-filled': sellPriceMin || sellPriceMax }">-->
          <!--                  {{ priceFilterText }}-->
          <!--                </div>-->
          <!--                <v-icon right>mdi-menu-down</v-icon>-->
          <!--              </div>-->
          <!--            </template>-->
          <!--            <div>-->
          <!--              <div class="menu&#45;&#45;title mb-4">-->
          <!--                {{ $t('general.filter.price') }}-->
          <!--              </div>-->
          <!--              <div>-->
          <!--                <cleave-->
          <!--                  v-model="sellPriceMin"-->
          <!--                  :placeholder="$t('general.min')"-->
          <!--                  :options="cleaveOptionsPrice"-->
          <!--                  class="basic&#45;&#45;input mb-2"-->
          <!--                  name="sellPriceMin"-->
          <!--                ></cleave>-->
          <!--              </div>-->
          <!--              <div>-->
          <!--                <cleave-->
          <!--                  v-model="sellPriceMax"-->
          <!--                  :placeholder="$t('general.max')"-->
          <!--                  :options="cleaveOptionsPrice"-->
          <!--                  class="basic&#45;&#45;input mb-4"-->
          <!--                  name="sellPriceMax"-->
          <!--                ></cleave>-->
          <!--              </div>-->
          <!--              <button class="btn btn-primary" type="button" @click="setSellPrice">-->
          <!--                {{ $t('general.filter.apply') }}-->
          <!--              </button>-->
          <!--            </div>-->
          <!--          </v-menu>-->
        </form>
        <!--        <div class="property&#45;&#45;types-wrapper">-->
        <!--          <router-link-->
        <!--            :to="`/cari${content.url}`"-->
        <!--            v-for="(content, index) in propertyTypes"-->
        <!--            class="property&#45;&#45;type-item"-->
        <!--            :key="`banner-property-type-${index}`"-->
        <!--          >-->
        <!--            <div class="img&#45;&#45;wrapper">-->
        <!--              <img :src="content.img" :alt="content.name" />-->
        <!--            </div>-->
        <!--            <div class="item&#45;&#45;text">{{ content.name }}</div>-->
        <!--          </router-link>-->
        <!--        </div>-->
        <!--        <div class="see&#45;&#45;all">-->
        <!--          <div>-->
        <!--            <div class="title&#45;&#45;text">-->
        <!--              {{ $t('helpDesk.helpFindResidence.question') }}-->
        <!--            </div>-->
        <!--            <router-link to="/bantu-cari-properti" style="text-decoration: none">-->
        <!--              <button class="btn">-->
        <!--                {{ $t('helpDesk.helpFindResidence.title') }}-->
        <!--              </button>-->
        <!--            </router-link>-->
        <!--          </div>-->
        <!--        </div>-->
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import { defineAsyncComponent } from 'vue';
// import LodashMixin from '@/mixins/lodash';

// const VueBootstrapTypeahead = () =>
//   import('@/components/utils/vue-bootstrap-typeahead/VueBootstrapTypeahead');
// const Cleave = () => import('vue-cleave-component');
const MultiselectRegion = defineAsyncComponent(() =>
  import('@/components/utils/partials/multiselect-region'),
);
export default {
  components: {
    // VueBootstrapTypeahead,
    // Cleave,
    MultiselectRegion,
  },
  // mixins: [LodashMixin],
  data: () => ({
    // cleaveOptionsPrice: {
    //   numeral: true,
    //   numeralDecimalMark: ',',
    //   delimiter: '.',
    //   prefix: 'Rp ',
    //   noImmediatePrefix: true,
    // },
    // suggestions: [],
    // getSuggestionLoading: false,
    tempMinSellPrice: null,
    tempMaxSellPrice: null,
    propertyTypes: [
      // {
      //   img:
      //     'https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/property-type/simple-house.png',
      //   name: 'Rumah Subsidi',
      //   url: '/rumah-subsidi',
      // },
      {
        img: 'https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/property-type/home.svg',
        name: 'Rumah',
        url: '/rumah',
      },
      {
        img: 'https://beliruma.s3-ap-southeast-1.amazonaws.com/public/static/img/property-type/condo.svg',
        name: 'Apartemen',
        url: '/apartemen',
      },
    ],
  }),
  computed: {
    ...mapState({
      searchTerm: (state) => state.v2.search.searchTerm,
      minBedrooms: (state) => state.v2.search.minBedrooms,
      propertyTypeFilters: (state) => state.v2.masters.propertyTypes,
    }),
    // currentKeyword: {
    //   get() {
    //     return this.$store.state.v2.search.currentKeyword;
    //   },
    //   set(value) {
    //     this.$store.commit('v2/search/SET_CURRENT_KEYWORD', value);
    //     this.$store.commit('v2/search/SET_SEARCH_TERM', value);
    //   },
    // },
    minBedroom: {
      get() {
        return this.$store.state.v2.search.minBedroom;
      },
      set(value) {
        this.$store.commit('v2/search/SET_MIN_BEDROOM', value);
      },
    },
    sellPriceMin: {
      get() {
        return this.$store.state.v2.search.sellPriceMin;
      },
      set(value) {
        this.tempMinSellPrice = value;
      },
    },
    sellPriceMax: {
      get() {
        return this.$store.state.v2.search.sellPriceMax;
      },
      set(value) {
        this.tempMaxSellPrice = value;
      },
    },
    priceFilterText() {
      if (this.sellPriceMin || this.sellPriceMax) {
        let text = '';
        if (this.sellPriceMin) text += this.sellPriceMin;
        if (this.sellPriceMin && this.sellPriceMax) text += '-';
        if (this.sellPriceMax) text += this.sellPriceMax;
        if (this.$mq !== 'xs') {
          if (text.length > 12) text = text.substring(0, 9) + '...';
        } else {
          if (text.length > 20) text = text.substring(0, 18) + '...';
        }
        return text;
      } else {
        return this.$t('general.filter.price');
      }
    },
    selectedPropertyType: {
      get() {
        return this.$store.state.v2.search.selectedPropertyType;
      },
      set(value) {
        this.$store.commit('v2/search/SET_SELECTED_PROPERTY_TYPE', value);
        if (value.id !== null) {
          this.$store.commit('v2/search/SET_SELECTED_PROPERTY_TYPES', [value]);
        } else {
          this.$store.commit('v2/search/SET_SELECTED_PROPERTY_TYPES', []);
        }
      },
    },
  },
  mounted() {
    // this.debouncedQuery = this.debounce(this.getSuggestions, 500);
    // this.currentKeyword = this.searchTerm;
    // this.replaceInputValue();
    this.$store.commit('v2/search/CLEAR_FILTER');
    this.$store.dispatch('v2/search/restoreInitialState');
  },
  methods: {
    // async getSuggestions(searchQuery) {
    //   try {
    //     this.getSuggestionLoading = true;
    //     this.currentKeyword = searchQuery;
    //     let response = await this.$store.dispatch('v2/search/getSuggestions', {
    //       query: searchQuery,
    //     });
    //     if (response.data !== null) {
    //       this.suggestions = response.data;
    //     } else {
    //       this.suggestions = [];
    //     }
    //   } finally {
    //     this.getSuggestionLoading = false;
    //   }
    // },
    // selectedSuggestion(data) {
    //   this.currentKeyword = data.name;
    //   this.$store.commit('v2/search/SET_SEARCH_TERM', data.name);
    //   this.$store.commit('v2/search/SET_SEARCH_TERM_ID', data.id);
    //   this.$store.commit('v2/search/SET_SEARCH_TERM_CATEGORY', data.category);
    // },
    // replaceInputValue() {
    //   let self = this;
    //   if (self.$refs.searchTypehead !== undefined) {
    //     self.$refs.searchTypehead.inputValue = this.searchTerm;
    //   } else {
    //     setTimeout(function() {
    //       self.replaceInputValue();
    //     }, 500);
    //   }
    // },
    setSellPrice() {
      this.$store.commit('v2/search/SET_SELL_PRICE_MIN', this.tempMinSellPrice);
      this.$store.commit('v2/search/SET_SELL_PRICE_MAX', this.tempMaxSellPrice);
      this.$refs.priceMenu.save();
    },
    goToSearch() {
      this.$store.commit('v2/search/SET_SELECTED_PROPERTY_TYPE', null);
      this.$store.dispatch('v2/search/goToSearchPage', {
        router: this.$router,
        params: this.$route.params,
      });
    },
  },
};
</script>
